<!--  -->
<template>
  <div class="sucaiku">
    <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose">
      <div class="div-dialog">
        <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
          @click="show = false">
        <div class="nav-div">
          <ul class="nav-ul">
            <li :class="{ 'active': selectIndex == 1 }" @click="selectItem(1)">图片({{imgCount}})</li>
            <li :class="{ 'active': selectIndex == 2 }" @click="selectItem(2)">视频({{viewCount}})</li>
            <li :class="{ 'active': selectIndex == 3 }" @click="selectItem(3)">文档({{TextCount}})</li>

          </ul>

        </div>
        <div class="list-div">
          <div class="list-item" v-for="item in list">
            
            <img class="fujian-pic" v-if="selectIndex == 1" :src="item.url">

<video class="fujian-pic" :src="item.url" v-else-if="selectIndex == 2"></video>
            
            <div class="div-fujian" v-else-if="selectIndex == 3">
              
              <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/fujianwendang.png" class="fujian-txt">
            </div>
            <p class="p-name">{{item.urlName}}</p>
            <div class="div-use" @click="userIndex(item)">使用</div>
          </div>
        </div>
        

      </div>
    </el-dialog>

  </div>
</template>

<script>
import common from "common/js/common";
import { Material } from "api/aiworker/aiworker.js";
export default {
  data () {
    return {

      show: false,
      selectIndex: 1,
      list: [],
      empId: null,
      fileUrl: '',
      imgCount: 0,
      viewCount: 0,
      TextCount:0
    }
  },
  methods: {
    showPopup1 (id1) {
      this.show = true
      this.empId = id1;
      this.getList();
    },
    handleClose () {

    },
    selectItem (index) {
      this.selectIndex = index;
      this.getList();
    },
    getList () { 
      Material(this.empId,this.selectIndex).then((res) => {
      if (res.code == 200) {
        console.log(res.data);
        this.list = res.data;
        this.TextCount = res.TextCount;
        this.viewCount = res.viewCount;
        this.imgCount = res.imgCount;
      
      } else if (res.code == 400) {
        if (!common.isStringEmpty(res.message)) {
          this.$message.error(res.message);
        }


      } else {

        if (!common.isStringEmpty(res.message)) {
          this.$message(res.message);
        }
      }
    }).catch((err) => {

      if (!common.isStringEmpty(JSON.stringify(err))) {
        this.$message(JSON.stringify(err.message));
      }
    });
    },
    userIndex (item) { 
      this.show = false;
      this.$emit('sucaiAction',item)
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

   
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-dialog {
  display: flex;
  position: relative;
  width: 100%;
  height:  100%;
  flex-direction: column;
}

.close_dialog {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.nav-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-bottom: 1px solid #E3EBF3;
  padding-bottom: 12px;


}

.nav-ul {
  display: flex;
  margin-left: 30px;
  cursor: pointer;

}

.nav-ul li {

  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 16px;
  margin-right: 24px;


}

.nav-div .active {
  position: relative;
  /* display: block !important; */
  color: #000000 !important;
  font-weight: 700;
}

.nav-div .active:after {
  position: absolute;
  left: 25%;
  top: 27px;
  content: '';
  width: 50%;
  height: 2px;
  background: #226CFF;
}

.list-div {

  flex: 1;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

}

.list-item {

  flex-shrink: 0;
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
  

}
.fujian-pic{
  width: 70px;
  height: 70px;
  border-radius: 8px 8px 8px 8px;
}
.div-fujian{
  width: 70px;
  height: 70px;
  background: #E4F2FF;
border-radius: 8px 8px 8px 8px;
display: flex;
justify-content: center;
align-items: center;
}

.fujian-txt{
  width: 40px;
  height: 40px;
}
.p-name{
  flex: 1;
  
  font-size: 15px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 12px;
}
.div-use{

  width: 64px;
height: 30px;
background: #FFFFFF;
border-radius: 19px 19px 19px 19px;

border: 1px solid #226CFF;

font-size: 14px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #226CFF;
line-height: 30px;
text-align: center;

}


.sucaiku /deep/.el-dialog {

  width: 700px;
  height: 500px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

.sucaiku /deep/.el-dialog__header {

  display: none;

}

.sucaiku /deep/.el-dialog__body {

  padding: 0;
}
</style>