<!--  -->
<template>
  <div class="div-content2">
    <div class="div-main">
      <div class="header-bg">
        <div class="header-div">“定制更精确的问答切片”
        </div>
        <div class="nav-div">
          <ul class="nav-ul">
            <li :class="{ 'active': selectIndex == 0 }" @click="selectItem(0)">全部切片</li>
            <li :class="{ 'active': selectIndex == 1 }" @click="selectItem(1)">本地导入</li>
            <li :class="{ 'active': selectIndex == 2 }" @click="selectItem(2)">在线录入</li>
            <li :class="{ 'active': selectIndex == 3 }" @click="selectItem(3)">文件智能提取</li>
            <li :class="{ 'active': selectIndex == 4 }" @click="selectItem(4)">核心问答</li>
          </ul>
          <div class="nav-right">
            <div> <el-input placeholder="请输入内容" v-model="input2" clearable @keyup.enter.native="searchQa"
                @clear="refreshQAList"><i slot="prefix"> <img class="search-img"
                    src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qasearch.png"></i>
              </el-input></div>

            <div class="nav-btn" @click="daochuQa">
              <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/daoru.png">
              <p>导出切片</p>
            </div>
            <div class="nav-btn" @click="showAddqpDialog">
              <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/daochu.png">
              <p>导入切片</p>
            </div>
            <div class="nav-btn" @click="openDrawer">
              <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/daoxin.png">
              <p>新建切片</p>
            </div>
          </div>

        </div>

        <div class="radio-div">
          <el-radio-group v-model="status" @change="changeStatus()">

            <el-radio :label="2" :disabled="deleteFlag" class="fangdaaction">全部状态</el-radio>
            <el-radio :label="1" :disabled="deleteFlag" class="fangdaaction">已启用</el-radio>
            <el-radio :label="0" :disabled="deleteFlag" class="fangdaaction">未启用</el-radio>
          </el-radio-group>
          <div class="pldelete" v-if="status == 0" @click="deleteQa">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/piliangxuan.png">
            <p>批量删除</p>
          </div>

          <div style="flex: 1;"></div>

          <div class="pltip" v-if="updateStatus2 == 1">

            <p>近期切片内容有更新，建议同步最新问答训练</p>
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qaljj.png">
          </div>

          <div class="pldatebase" @click="updateDatabase">
            <img :class="{ 'rotate-div': tongbuFlag }"
              src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qarefresh2.png">
            <p>同步最新问答训练</p>
          </div>
          <div class="pldelete div-magin" @click="showDialog(1)">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianjin.png">
            <p>全部禁用</p>
          </div>


          <div class="pldelete div-magin" @click="showDialog(2)">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianqi.png">
            <p>全部启用</p>
          </div>

        </div>
      </div>


      <div class="list-div" v-if="roleList !== undefined && roleList != null && roleList.length > 0">
        <div class="list-item" v-for="(item, index) in roleList" :key="item.id">
          <div class="ask-div" @click="showQadetailDialog(index)">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianwen.png">
            <p>{{ item.question }}</p>
          </div>
          <div class="ans-div" @click="showQadetailDialog(index)">
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianda.png">
            <p>{{ item.answer }}</p>
          </div>

          <div class="line-div"></div>
          <div class="bottom-div">
            <p class="p-type">{{ item.sourceView }}</p>
            <div class="file-div">
              <div class="file-div1" v-if="item.urlInfo !== undefined && item.urlInfo != null && item.urlInfo.length > 0"
                @click="looktypeSrc2(item.urlInfo,index)">
                <img :src="toTypesrc(item.urlInfo)">
                <p>{{ toTypeName(item.urlInfo) }}</p>

              </div>
            </div>

            <div class="switch-div">
              <el-switch v-model="item.status" :width="32" active-color='#226CFF' :active-value="1" :inactive-value="0"
                :disabled="deleteFlag" @change="switchChange(item.id, item.status)">
              </el-switch>
              <p>{{ item.status == 1 ? '已启用' : '未启用' }}</p>
            </div>

          </div>

          <div class="hexin-div" v-if="item.coreStatus == 'Y'">核心问答</div>
          <div class="check-div div-check" :class="{ domdisplay: deleteFlag }">
            <el-checkbox v-model="item.checked" @change="calcCheckCount(item.checked, item.id)"></el-checkbox>

          </div>


        </div>

      </div>
      <div class="empty-qiepian" v-else>
        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/emptyqp.png">

        <p>暂无切片内容，快去创建一个吧</p>
      </div>
      <el-drawer size="50%" title="我是标题" :visible.sync="drawer" :before-close="handleClose" :with-header="false">
        <div class="drawer-div">
          <div class="drawer-head">
            <p>{{ drawerFlag == false ? '新建切片' : '编辑切片' }}</p>
            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png" @click="closeDrawer">
          </div>
          <div class="drawer-wenda">
            <p class="drawer-wenda-title">客户问题</p>
            <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 5 }" placeholder="请输入客户问题…" v-model="qaques"
              maxlength="500" show-word-limit>
            </el-input>
          </div>
          <div class="drawer-wenda">
            <p class="drawer-wenda-title">对应回答</p>
            <el-input type="textarea" :autosize="{ minRows: 9, maxRows: 9 }" placeholder="请输入对应的回答…" v-model="qaans"
              maxlength="3000" show-word-limit>
            </el-input>
          </div>
          <div class="drawer-wenda" style="display: flex;align-items: center;">
            <p class="drawer-wenda-title1">添加附件</p>
            <p class="drawer-wenda-title2">已上传的图片/视频/文档 会被保存在该员工共用素材库中</p>
          </div>

          <div class="drawer-sucai" v-loading="imgloading">

            <div class="fujian-item" v-for="(item, index) in fileUrlinfo">



              <div class="fujian-content">



                <img class="fujian-pic" v-if="item.type != null && item.type == 1" :src="item.url"
                  @click="looktypeSrc(item.url)">

                <video class="fujian-pic" :src="item.url" v-else-if="item.type != null && item.type == 2"
                  @click="looktypeSrc(item.url)"></video>



                <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/fujianwendang.png" class="fujian-txt"
                  v-else-if="item.type != null && item.type == 3" @click="looktypeSrc(item.url)">

                <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/fujianshanchu.png" class="fujian-delete"
                  @click="clearFujian(index)">
              </div>
              <p class="fujian-pname">
                {{ item.urlName }}
              </p>




            </div>


            <div class="sucai-xuan" v-if="fileUrlinfo.length < 6">

              <el-upload action="" :show-file-list="false" :before-upload="beforeUpload1" :http-request="httpRequest1">
                <div class="sucai-xuan-bg">
                  <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianbendi.png">
                  <p>从本地上传</p>
                </div>

              </el-upload>


              <div class="sucai-xuan-bg" @click="openSucai()">
                <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/drawer_sucai.png">
                <p>从素材库选择</p>
              </div>

            </div>

          </div>


          <div class="div-sub">
            <button class="cancle-btn" @click="closeDrawer(1)">
              取消编辑
            </button>
            <el-button type="primary" :disabled="!(qaques && qaans)" :loading="btnloading"
              @click="baocunQiepian">保存内容</el-button>

          </div>
        </div>

      </el-drawer>
    </div>


    <el-dialog :visible.sync="dialogshow" :show-close="false">
      <div class="div-dialog">
        <div class="dialog-head">
          <p class="dialog-head-p">操作提示</p>
          <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
            @click="dialogshow = false">
        </div>

        <div class="dialog-content">

          <p>{{ dialogcontent }}</p>

        </div>

        <div class="dialog-bottom">

          <button class="dialog-negative" @click="dialogshow = false">取消</button>
          <button class="dialog-positive" @click="dialogConfim">确认</button>
        </div>
      </div>

    </el-dialog>

    <div class="delete-diloag div-check" :class="{ domdisplay: deleteFlag }">

      <el-checkbox v-model="checkedAll" style="margin-left: 18px;" @change="setCheckAll">全部选择</el-checkbox>
      <p class="delete-p">已选择{{ checkCount }}条</p>
      <div class="btm-btn">
        <button class="delete-cancle-btn" @click="cancelDelete">
          取消
        </button>
        <el-button type="primary" @click="showDialog(3)">删除所选切片</el-button>


      </div>
    </div>

    <AddqiepianDialog ref="childShare" @addconfimAction="refreshQAList"></AddqiepianDialog>
    <QadetailDialog ref="qachildOpen" :qadetailObj="qadetailObj" @qadetailAction="qadetailAction"></QadetailDialog>

    <TongbuDialog ref="tongbuOpen" @tongbuAction="updateDatabase"></TongbuDialog>
    <SucaikuDialog ref="sucaiOpen" @sucaiAction="sucaiAction"></SucaikuDialog>
  </div>
</template>

<script>
import common from "common/js/common";
import AddqiepianDialog from "./cells/Addqiepian.vue";
import QadetailDialog from "./cells/Qadetail.vue";
import TongbuDialog from "./cells/TongbuDialog.vue";
import SucaikuDialog from "./cells/Sucaiku.vue";
import { findQAList, updateQAStatus, addOrUpdateQA, deleteQA, CSVImageUpload, UpdateToAicc, fileStatusV2, QAupdateStatus, exportCSV ,CSVImageUpload2} from "api/aiworker/aiworker.js";

export default {
  components: {
    AddqiepianDialog,
    QadetailDialog,
    SucaikuDialog,
    TongbuDialog
  },
  data () {
    return {
      deleteFlag: false,

      checkshow: false,
      empTypePrompt: "",
      btnloading: false,
      dialogshow: false,
      dialogType: 1,
      dialogcontent: "",
      selectIndex: 0,
      input2: "",
      radio: 1,
      roleList: null,
      value1: true,
      checkedAll: false,
      checkCount: 0,
      checkArr: [],
      drawer: false,
      drawerFlag: false,
      empId: null,
      status: 2,
      sourceFrom: 3,
      tempId: '',
      qadetailObj: {
      },
      qaques: '',
      qaans: '',
      sucaiflag: true,
      imgloading: false,
      searchFlag: false,
      updateStatus2: null,
      tongbuFlag: false,


      fileUrlinfo: [],

    }
  },
  methods: {

    deleteQa () {
      this.deleteFlag = true;

    },
    searchQa () {
      if (common.isStringEmpty(this.input2)) {
        this.$message("请输入内容");
        return;
      }
      this.searchFlag = true;
      this.sourceFrom = 3;
      this.selectItem(0);
      this.status = 2;




    },

    updateDatabase () {
      this.tongbuFlag = true;
      UpdateToAicc(this.empId).then((res) => {

        if (res.code == 200) {

          this.$message({
            message: '开始同步，等待同步结果',
            type: 'success',
            duration: 5000

          });
          this.refreshFileStatusV1();

        } else if (res.code == 400) {
          this.tongbuFlag = false;
          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          }


        } else {
          this.tongbuFlag = false;
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {
        this.tongbuFlag = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });
    },


    refreshFileStatusV1 () {
      setTimeout(() => {
        fileStatusV2(this.empId).then((res) => {
          console.log(res);

          if (res.code == 200) {

            if (res.data == 0) {
              this.tongbuFlag = false;
              this.$message({
                message: '同步成功',
                type: 'success',

              });
              this.updateStatus2 = 0

              QAupdateStatus(this.empId).then((res) => {

              }).catch((err) => {

              });


            } else if (res.data == 1) {
              this.tongbuFlag = false;

              this.$message({
                message: '同步失败，请重新尝试同步',
                type: 'error',
                duration: 5000
              });


            } else {
              this.refreshFileStatusV1();
            }
          }


        }).catch((err) => {
          this.tongbuFlag = false;
        });

      }, 3000);
    },


    showQadetailDialog (index) {
      if (this.deleteFlag) {
        return;
      }
      this.qadetailObj = this.roleList[index];

      this.$refs.qachildOpen.showPopup1();
    },
    qadetailAction (index1, id2) {
      if (index1 == 1) {

        this.showDialog(4, id2);

      } else if (index1 == 3) {
        this.refreshQAList();
      } else {
        console.log("------")
        console.log(this.qadetailObj);

        this.drawerFlag = true;
        this.drawer = true;
        this.qaques = this.qadetailObj.question;
        this.qaans = this.qadetailObj.answer;
        if (this.qadetailObj.urlInfo !== undefined && this.qadetailObj.urlInfo != null && this.qadetailObj.urlInfo.length > 0) {
          this.fileUrlinfo = this.qadetailObj.urlInfo;
        }

      }
    },

    daochuQa () {

      exportCSV(this.empId).then((res) => {

        const objectUrl = URL.createObjectURL(
          new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        )
        const link = document.createElement('a')
        // 设置导出的文件名称
        link.download = `全部切片` + '.csv'
        link.style.display = 'none'
        link.href = objectUrl
        link.click()
        document.body.appendChild(link)

      }).catch((err) => {
        console.log(err)
      });
    }
    ,
    showAddqpDialog () {
      if (this.deleteFlag) {
        return;
      }

      this.$refs.childShare.showPopup1(this.empId);
    },

    showDialog (val, val2) {
      if (val == 3) {

        if (this.checkArr.length == 0) {
          this.$message('请选择要删除的条目');
          return;
        }

      } else {
        if (this.deleteFlag) {
          return;
        }
      }


      this.dialogshow = true;
      this.dialogType = val;
      switch (val) {
        case 1:
          this.dialogcontent = "是否确定禁用全部切片？"
          break
        case 2:
          this.dialogcontent = "是否确定启用全部切片？"
          break
        case 3:
          this.dialogcontent = "删除后不可恢复，是否确认？"
          break
        case 4:
          this.tempId = val2;
          this.dialogcontent = "删除后不可恢复，是否确认？"
          break
        case 5:
          this.dialogcontent = "当前页面内容未保存，是否确认退出？"
          break

      }

    },

    dialogConfim () {
      this.dialogshow = false;
      if (this.dialogType == 1) {
        console.log(this.dialogType + "111111111");
        this.updateStatus("", 0);
      } else if (this.dialogType == 2) {
        this.updateStatus("", 1);
      } else if (this.dialogType == 3) {
        this.deleteQaAll(this.checkArr);
      } else if (this.dialogType == 4) {
        let arr = new Array();
        arr.push(this.tempId);
        this.deleteQaAll(arr);

      } else if (this.dialogType == 5) {
        this.drawer = false;
        this.drawerFlag = false;

      }

    },
    switchChange (val1, val2) {
      console.log(val2);
      this.updateStatus(val1, val2);
    },
    changeStatus () {
      this.refreshQAList();
    },
    selectItem (index) {
      if (this.deleteFlag) {
        return;
      }
      this.selectIndex = index;
      switch (index) {
        case 0:
          this.sourceFrom = 3
          break;
        case 1:
          this.sourceFrom = 1
          break;
        case 2:
          this.sourceFrom = 2
          break;
        case 3:
          this.sourceFrom = 4
          break;
        case 4:
          this.sourceFrom = 5
          break;
      }
      this.refreshQAList();
    },
    openDrawer () {
      if (this.deleteFlag) {
        return;
      }
      this.qadetailObj = {};

      this.drawer = true;
      this.drawerFlag = false;

      this.qaques = '';
      this.qaans = '';
      this.fileUrlinfo = [];
    },
    closeDrawer (flag1) {

      if (flag1 == 1) {
        if (!common.isStringEmpty(this.qaques) && !common.isStringEmpty(this.qaans)) {
          this.showDialog(5);
        } else {
          this.drawer = false;
          this.drawerFlag = false;
        }


      } else {
        this.drawer = false;
        this.drawerFlag = false;
      }


    },
    handleClose () {

    },
    looktypeSrc2 (item,index) {
      if (item.length > 1) {

        this.showQadetailDialog(index);
      } else { 
        window.open(item[0].url);//直接添加链接可以跳转
      }
     
    },

    looktypeSrc (url2) {
      window.open(url2);//直接添加链接可以跳转
    },

    toTypesrc (item) {
      if (item.length > 1) {
        return 'https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianfujian.png';
      } else {
        if (1 == item[0].type) {
          return 'https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepiantu.png';
        } else if (2 == item[0].type) {
          return 'https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepianshi.png';
        } else {
          return 'https://cdn.shhd.info/APP/sys_img/digitalEmployee/qiepiantxt.png';
        }
      }

    },
    toTypeName (item) {
      if (item.length > 1) {
        return "已绑定" + item.length + "个附件";
      } else {
        return item[0].urlName;
      }
    },
    refreshQAList () {


      var f = new FormData()
      f.append('empId', this.empId)
      f.append('status', this.status)
      f.append('sourceFrom', this.sourceFrom)
      if (this.searchFlag) {
        f.append('askContent', this.input2)
      }

      findQAList(f).then((res) => {
        this.searchFlag = false;

        if (res.code == 200) {

          this.roleList = res.data;
          this.updateStatus2 = res.updateStatus;
        } else if (res.code == 400) {
          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          }


        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {
        this.searchFlag = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });

    },
    updateStatus (val1, val2) {

      let obj = {
        "status": val2
      }
      obj.empId = this.empId
      if (!common.isStringEmpty(val1)) {
        obj.id = val1
      }
      updateQAStatus(obj).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res);

          if (val2 == 1) {
            this.$message({
              message: '启用成功',
              type: 'success'
            });
          } else {
            this.$message({
              message: '禁用成功',
              type: 'success'
            });
          }

          if (common.isStringEmpty(val1)) {

            this.refreshQAList();
          } else {
            this.updateStatus2 = 1;
          }
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });

    },
    baocunQiepian () {
      this.btnloading = true;
      let obj = {
      }
      obj.empId = this.empId;
      obj.question = this.qaques;
      obj.answer = this.qaans;
      if (this.drawerFlag) {
        obj.id = this.qadetailObj.id;
      }
      if (this.fileUrlinfo != null && this.fileUrlinfo.length > 0) {
        obj.urlInfo = this.fileUrlinfo;
      }

      addOrUpdateQA(obj).then((res) => {
        console.log(res);
        this.btnloading = false;
        if (res.code == 200) {
          console.log(res);
          if (this.drawerFlag) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          } else {
            this.$message({
              message: '添加成功',
              type: 'success'
            });
          }
          this.qaans = '';
          this.qaques = '';
          this.drawer = false;
          this.drawerFlag = false;

          this.fileUrlinfo = [];

          this.refreshQAList();
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {
        this.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });

    },

    deleteQaAll (arr) {
      let obj = {
        "ids": arr,
        'empId': this.empId,
      }
      deleteQA(obj).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.deleteFlag = false;
          this.checkedAll = false;
          this.checkCount = 0;
          this.checkArr = [];
          console.log(res);
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          this.refreshQAList();
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });
    },

    cancelDelete () {
      this.deleteFlag = false;
    },
    setCheckAll (val) {

      if (val) {
        for (var i = 0; i < this.roleList.length; i++) {
          if (!this.roleList[i].checked) {
            this.roleList[i].checked = true;
          }
          this.checkArr.push(this.roleList[i].id);
        }
        this.checkCount = this.roleList.length;

      } else {
        for (var i = 0; i < this.roleList.length; i++) {
          if (this.roleList[i].checked) {
            this.roleList[i].checked = false;
          }
        }
        this.checkCount = 0;
        this.checkArr = [];
      }


    },
    getCheckCount () {


    },
    calcCheckCount (val2, id2) {

      if (val2) {
        this.checkCount++;
        this.checkArr.push(id2);
      } else {
        this.checkCount--;
        this.checkArr.splice(this.checkArr.indexOf(id2), 1);
      }
      console.log(this.checkArr);
    },

    beforeUpload1 (file) {


      console.log(file);
      let fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);

      if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'mp4' || fileExtension === 'mov' || fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'xlsx' || fileExtension === 'pdf') {
        // 处理符合要求的文件
        if (file.size / 1024 / 1024 < 20) {

          return true;
        } else {
          this.$message.error('上传附件大小不能超过20M！');
          return false;
        }

      } else {
        this.$message.error('请上传对应格式的图片或视频或文档');
        return false;
      }
    },

    httpRequest1 (file2) {




      let fileExtension = file2.file.name.slice(file2.file.name.lastIndexOf('.') + 1);
      this.imgloading = true;

      let fd = new FormData();
      fd.append('access_token', this.$localstore.get("access_token"));
      fd.append('file', file2.file)
      CSVImageUpload2(fd).then((res) => {
        this.imgloading = false;
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            let obj = new Object();
            obj.type = 1;
            obj.url = res.data.fileUrl;
            obj.urlName = res.data.fileName;
            this.fileUrlinfo.push(obj);

          } else if (fileExtension === 'mp4' || fileExtension === 'mov') {
            let obj = new Object();
            obj.type = 2;
            obj.url = res.data.fileUrl;
            obj.urlName = res.data.fileName;
            this.fileUrlinfo.push(obj);
          } else if (fileExtension === 'docx' || fileExtension === 'doc' || fileExtension === 'xlsx' || fileExtension === 'pdf') {
            let obj = new Object();
            obj.type = 3;
            obj.url = res.data.fileUrl;
            obj.urlName = res.data.fileName;
            this.fileUrlinfo.push(obj);

          }


        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          } else {
            this.$message.error('上传失败，请重试！');
          }

        }
      }).catch((err) => {
        this.imgloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });

    },

    clearFujian (index) {

      this.fileUrlinfo.splice(index, 1);
    },
    openSucai () {
      this.$refs.sucaiOpen.showPopup1(this.empId);
    },

    sucaiAction (item) {

      let obj = new Object();
      obj.type = item.type;
      obj.url = item.url;
      obj.urlName = item.urlName;
      this.fileUrlinfo.push(obj);

    },

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
    this.empId = this.$Base64.decode(this.$route.query.eid);
    var f = new FormData()
    f.append('empId', this.empId)
    f.append('status', this.status)
    f.append('sourceFrom', this.sourceFrom)
    findQAList(f).then((res) => {
      if (res.code == 200) {
        console.log(res.data);
        this.roleList = res.data;
        this.updateStatus2 = res.updateStatus;
        if (1 == res.updateStatus) {
          this.$refs.tongbuOpen.showPopup1();

        }
      } else if (res.code == 400) {
        if (!common.isStringEmpty(res.message)) {
          this.$message.error(res.message);
        }


      } else {

        if (!common.isStringEmpty(res.message)) {
          this.$message(res.message);
        }
      }
    }).catch((err) => {

      if (!common.isStringEmpty(JSON.stringify(err))) {
        this.$message(JSON.stringify(err.message));
      }
    });
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-content2 {
  width: 1400px;
  margin: 0 auto;
}

.div-main {

  width: 100%;

}

.header-bg {

  background-image: url('https://cdn.shhd.info/APP/sys_img/digitalEmployee/aiqabg.png');
  background-size: cover;
}

.header-div {


  width: 100%;
  padding-top: 60px;
  text-align: center;
  font-size: 40px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 40px;
  background: linear-gradient(90deg, #19AAFF 0%, #1657FF 46%, #726AFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-bottom: 1px solid #E3EBF3;
  padding-bottom: 8px;


}

.nav-ul {
  display: flex;
  padding-bottom: 8px;
  cursor: pointer;

}

.nav-ul li {

  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 18px;
  margin-right: 25px;


}

.nav-right {
  display: flex;
}

.nav-btn {
  width: 115px;
  height: 47px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(9, 56, 128, 0.05);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.nav-btn img {
  width: 17px;
  height: 17px;
}

.nav-btn p {
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 15px;
  margin-left: 3px;
}

.search-img {
  width: 21px;
  height: 21px;
  margin-top: 13px;
}

/**改变input里的字体颜色*/
/deep/ input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 15px;
}

/deep/ .el-input__inner {
  width: 360px;
  height: 47px;
  line-height: 47px;
  font-size: 15px;
  background-color: #F9FAFB;
  border-radius: 8px 8px 8px 8px;
  color: #333333;
  border: 1px solid #D7E4F3;
}

/deep/ .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0;
}

/deep/ .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0;
}

.radio-div {
  display: flex;
  height: 60px;
  align-items: center;
}

.fangdaaction {}

.radio-div /deep/ .el-radio {
  margin-right: 20px;
}

.radio-div /deep/ .el-radio__label {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  vertical-align: middle;
}

.radio-div /deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #226CFF !important;
}

.radio-div /deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #000000;
}

.list-div {
  display: flex;
  flex-wrap: wrap;

}

.list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px 5px;
  width: 24.2%;
  height: 280px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(9, 56, 128, 0.05);
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
}

.list-item:hover {
  border: 1px solid #65AAFB;
}

.ask-div {
  display: flex;
  margin: 16px 10px;
}

.ask-div img {
  width: 20px;
  height: 20px;
}

.ask-div p {
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-left: 6px;
  line-height: 22px;
  /* 设置多行*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* 设置省略字母数字 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ans-div {
  display: flex;
  flex: 1;
  margin: 0px 10px;

}

.ans-div img {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.ans-div p {
  margin-left: 6px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 22px;
  /* 设置多行*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* 设置省略字母数字 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-div {
  height: 0px;
  border: 0.5px solid #EAF1F5;
  margin: 15px 10px 0px;
}

.bottom-div {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
}

.p-type {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #4C73AE;
  line-height: 12px;
  background: #EEF5FB;
  border-radius: 14px 14px 14px 14px;
  padding: 8px 10px;
  margin-left: 10px;
}

.file-div {
  flex: 1;

}

.file-div1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.file-div1 :hover {
  border-bottom: 1px solid #226CFF;
}

.file-div1 img {
  width: 22px;
  height: 22px;
}

.file-div1 p {
  max-width: 113px;
  font-size: 14px;
  margin-left: 2px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;

  /*设置单行 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.switch-div {

  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;

}

.switch-div p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  margin-left: 4px;

}

.hexin-div {
  width: 72px;
  height: 28px;
  background: linear-gradient(172deg, #ECD3A3 0%, #F0E7D6 100%);
  border-radius: 15px 15px 15px 15px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #584624;
  line-height: 28px;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 7px;
}

.check-div {
  display: none;
  position: absolute;
  right: 10px;
  top: 7px;

}

.nav-div .active {
  position: relative;
  /* display: block !important; */
  color: #000000 !important;
  font-weight: 700;
}

.nav-div .active:after {
  position: absolute;
  left: 25%;
  top: 44px;
  content: '';
  width: 50%;
  height: 2px;
  background: #226CFF;
}


.empty-qiepian {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  align-self: center;
  justify-content: center;
}

.empty-qiepian img {
  width: 150px;
  height: 150px;
}

.empty-qiepian p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 14px;

}

/*抽屉css */
.drawer-div {
  width: 100%;
  height: 100%;
  background: #F2F7FC;
  display: flex;
  padding: 15px 20px;
  flex-direction: column;
}

.drawer-head {
  display: flex;
}

.drawer-head p {
  flex: 1;
  font-size: 26px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 26px;
}

.drawer-head img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.drawer-wenda {
  margin-top: 20px;
}

.drawer-wenda-title {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

}

.drawer-wenda-title1 {
  font-size: 18px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 18px;



}

.drawer-wenda-title2 {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
  margin-left: 6px;

}

/deep/ .el-textarea__inner {
  font-size: 15px;
  color: #333333;
}


.drawer-sucai {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;

}

.fujian-item {
  display: flex;
  flex-direction: column;
  width: 120px;
  margin-right: 10px;
  margin-bottom: 12px;
}

.fujian-content {
  position: relative;
  width: 120px;
  height: 120px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fujian-pname {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 14px;
  margin-top: 14px;
  /*设置单行 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sucai-xuan {
  display: flex;
  flex-direction: column;
}

.sucai-xuan-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 60px;
  background: #FFFFFF;
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 7px;
}

.sucai-xuan-bg img {
  width: 30px;
  height: 30px;
}

.sucai-xuan-bg p {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);

  margin-top: 3px;
}

.sucai-xuan-bg:hover {
  border: 1px solid #226CFF;
}

.drawer-sucai-bg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 94px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin-right: 16px;
}

.drawer-fujian {

  position: relative;
  width: 160px;
  height: 160px;
  margin-top: 14px;
  border-radius: 10px 10px 10px 10px;
  background: #FFFFFF;
}

.fujian-pic {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;

}

.fujian-name {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
  margin-top: 4px;
}

.fujian-txt {

  width: 60px;
  height: 60px;


}

.fujian-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 28px;
  height: 28px;
  cursor: pointer;

}

.drawer-sucai-bg img {
  width: 40px;
  height: 40px;
}

.drawer-sucai-bg p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 14px;
  margin-top: 8px;
}

.div-sub {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;

}

.cancle-btn {
  width: 280px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #226CFF;
  font-size: 16px;
  background-color: #ffffff;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #226CFF;
  text-align: center;
  margin-right: 15px;
}

/deep/ .el-button {
  width: 280px;
  height: 52px;
  background: #226CFF;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px 10px 10px 10px;

}

/deep/.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {

  background-color: #a0cfff;
  border-color: #a0cfff;
}

.pltip {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.pltip p {

  height: 32px;
  background: #226CFF;
  border-radius: 8px 8px 8px 8px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 32px;
  padding-left: 12px;
  padding-right: 12px;
}

.pltip img {
  width: 5px;
  height: 8px;

}

.pldatebase {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pldatebase img {
  width: 20px;
  height: 20px;
}

.pldatebase p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7F8995;
  line-height: 14px;
  margin-left: 2px;
}

.pldelete {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pldelete img {
  width: 20px;
  height: 20px;
}

.pldelete p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7F8995;
  line-height: 14px;
  margin-left: 2px;
}

.div-magin {
  margin-left: 24px;
}



/deep/.el-dialog {

  width: 400px;
  height: 260px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

/deep/.el-dialog__header {

  display: none;

}

/deep/.el-dialog__body {

  height: 100%;
  padding: 0;
}

.pop-content {
  display: flex;
}

.div-dialog {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog {
  width: 36px;
  height: 36px;

  align-self: flex-end;
  position: absolute;
  right: 12px;

}

.dialog-head {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.dialog-head-p {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}

.dialog-content {
  display: flex;
  width: 100%;
  flex: auto;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;


}

.dialog-bottom {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
}



.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
}

.delete-diloag {
  z-index: 999;
  position: fixed;
  display: none;
  align-items: center;
  width: 800px;
  height: 76px;
  background: #E8F3FF;
  box-shadow: 0px 8px 26px 0px rgba(48, 74, 125, 0.14);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #F4F9FF;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.div-check /deep/ .el-checkbox__inner {
  width: 18px;
  height: 18px;
  border-radius: 50% !important;
}


.div-check /deep/ .el-checkbox__inner::after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 6px;
  top: 4px;
}


.div-check /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.1);
}


.delete-p {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 14px;
  margin-left: 15px;
}

.btm-btn {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.delete-cancle-btn {
  width: 160px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #D7E4F3;
  font-size: 16px;
  background-color: #ffffff;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-right: 15px;
}

.delete-diloag /deep/ .el-button {
  width: 160px;
  height: 52px;
  background: #226CFF;
  font-size: 16px;
  font-weight: 500;

  border-radius: 10px 10px 10px 10px;
  margin-right: 30px;
}

.domdisplay {
  display: flex;
}


.rotate-div {

  animation: rotate 1s infinite linear;
  /* 实现旋转动画效果 */
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}</style>