<!--  -->
<template>
<div class="tongbu-div">

  <el-dialog :visible.sync="show" :show-close="false" >
    <div class="tongbu-dialog">
      <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"  @click="show=false">
      <img class="success_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/qatongbubg.png" >
      <p class="dialog_title">切片有更新，建议同步最新问答训练</p>
      <p class="dialog_p">切片更新后，需同步最新问答训练后才可在前台页面中调用</p>
      <div class="dialog_btn1" @click="tongbuAction">立即同步</div>
      <div class="dialog_btn2" @click="show=false">
        <p class="btn2_p1">暂不同步</p>
        
      </div>
    </div>

  </el-dialog>


</div>
</template>

<script>
export default {
data() {
return {
  show: false,
}
  },
  methods:{ 

    showPopup1 () {
       
        this.show = true;
    },
    tongbuAction(){ 
      this.show = false;
      this.$emit('tongbuAction')
    }
  },
//生命周期 - 创建完成（访问当前this实例）
created() {

},
//生命周期 - 挂载完成（访问DOM元素）
mounted() {

}
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.tongbu-dialog{
  display: flex;
  flex-direction: column;
}
.close_dialog{
  width: 36px;
height: 36px;
margin-top: 12px;
margin-right: 12px;
align-self: flex-end;
cursor: pointer;
}
.success_dialog{
  width: 90px;
height: 90px;
margin-top: 8px;
align-self: center;
}
.dialog_title{
  font-size: 18px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
margin-top: 18px;

align-self: center;
color: #000000;
}
.dialog_p{
  font-size: 13px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.5);
line-height: 15px;
margin-top: 16px;
align-self: center;
}

.dialog_btn1{
  width: 348px;
  align-self: center;
  height: 60px;
background: #226CFF;
border-radius: 10px 10px 10px 10px;
font-size: 16px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #FFFFFF;
line-height: 60px;
margin-top: 50px;
text-align: center;
}


.dialog_btn2{
  display: flex;
  width: 348px;
  align-self: center;
  height: 60px;
  background: #FFFFFF;
border-radius: 10px 10px 10px 10px;
border: 1px solid #D7E4F3;
margin-top: 18px;
flex-direction: column;
justify-content: center;
align-items: center;
}

.btn2_p1{
  font-size: 16px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 16px
}
.btn2_p2{
  font-size: 10px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.4);
line-height: 10px;
margin-top: 6px;
}

.tongbu-div /deep/.el-dialog {

  width: 400px;
height: 461px;
background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
border-radius: 14px 14px 14px 14px;
opacity: 1;
border: 1px solid #FFFFFF;
}
.tongbu-div /deep/.el-dialog__header {

  display: none;

}
.tongbu-div /deep/.el-dialog__body {

  padding:0;
}
.pop-content{
  display: flex;
}
</style>