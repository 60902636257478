<!-- 切片详情 -->
<template>
  <div class="qadetail">

    <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose">
      <div class="div-dialog">
        <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
          @click="closeDialog">
        <p class="ask-p">

          {{ qadetailObj.question }}
        </p>
        <div class="ans-p"> {{ qadetailObj.answer }}</div>


        <div class="drawer-sucai">
        <div class="fujian-item" v-for="(item, index) in qadetailObj.urlInfo">



          <div class="fujian-content">



            <img class="fujian-pic" v-if="item.type != null && item.type == 1" :src="item.url"
              @click="looktypeSrc(item.url)">

            <video class="fujian-pic" :src="item.url" v-else-if="item.type != null && item.type == 2"
              @click="looktypeSrc(item.url)"></video>



            <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/fujianwendang.png" class="fujian-txt"
              v-else-if="item.type != null && item.type == 3" @click="looktypeSrc(item.url)">


          </div>
          <p class="fujian-pname">
            {{ item.urlName }}
          </p>




        </div>
       </div>

        <div class="btm-btn">

          <el-checkbox v-model="qadetailObj.coreStatus" true-label="Y" false-label="N"
            @change="getSwitchChecked"></el-checkbox>
          <p>标记为核心问答切片</p>
          <div class="btm-btn1">
            <button class="cancle-btn" @click.stop="qadetailAction(1)">
              删除
            </button>
            <button class="confim-btn" @click.stop="qadetailAction(2)">
              编辑
            </button>
          </div>



        </div>

      </div>

    </el-dialog>
  </div>
</template>

<script>
import common from "common/js/common";
import { updateCoreStatus } from "api/aiworker/aiworker.js";
export default {
  data () {
    return {
      show: false,
      editFlag: false
    }
  },

  props: {
    qadetailObj: {
      empId: Number,
      id: Number,
      answer: String,
      default () {
        return "";
      },
      question: String,
      default () {
        return "";
      },
      coreStatus: String,
      default () {
        return "N";
      },

      urlInfo: Array,
      default () {
        return [];
      },
    },
  },

  methods: {
    showPopup1 () {

      this.show = true;

    },
    closeDialog () {
      this.show = false;
      if (this.editFlag) {
        this.editFlag = false;
        this.$emit('qadetailAction', 3, null)
      } else {

      }
    },
    handleClose (done) {

    },
    looktypeSrc (url2) {
      window.open(url2);//直接添加链接可以跳转
    },
    getSwitchChecked () {

      updateCoreStatus(this.qadetailObj.id, this.qadetailObj.empId, this.qadetailObj.coreStatus).then((res) => {
        console.log(res);
        this.editFlag = true;
        if (res.code == 200) {

          if ('Y' == this.qadetailObj.coreStatus) {
            this.$message({
              message: '设置成功',
              type: 'success'
            });
          } else {
            this.$message({
              message: '取消成功',
              type: 'success'
            });
          }
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });

    },

    qadetailAction (index) {

      this.show = false;
      this.$emit('qadetailAction', index, this.qadetailObj.id)

    },
  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-dialog {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
}

.close_dialog {
  width: 36px;
  height: 36px;
  margin-top: 12px;
  margin-right: 12px;
  align-self: flex-end;
  cursor: pointer;
}

.qadetail /deep/.el-dialog {

  width: 700px;
  height: 500px;
  background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}

.qadetail /deep/.el-dialog__header {

  display: none;

}

.qadetail /deep/.el-dialog__body {

  padding: 0;
}

.ask-p {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
  margin-left: 30px;
  margin-right: 30px;

}

.ans-p {
  flex: 1;
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 26px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 20px;

}

.btm-btn {
  display: flex;
  margin-top: 20px;
  height: 70px;
  margin-left: 30px;
  align-items: center;
}

.btm-btn p {

  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 14px;
  margin-left: 5px;
}

.btm-btn1 {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.cancle-btn {
  width: 160px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #D7E4F3;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-right: 15px;
  cursor: pointer;
}

.confim-btn {
  width: 160px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #D7E4F3;
  font-size: 16px;
  background-color: #226CFF;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  margin-right: 30px;
}

.btm-btn /deep/ .el-checkbox__inner {
  width: 14px;
  height: 14px;
  vertical-align: middle;

}

.btm-btn /deep/ .el-checkbox__inner::after {
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 5px;
  top: 3px;
}

.btm-btn /deep/ .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.2);
}


.fujian-item {
  display: flex;
  flex-direction: column;
  width: 90px;
  margin-right: 10px;
  margin-bottom: 12px;
}

.fujian-content {
  position: relative;
  width: 90px;
  height: 90px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fujian-pname {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 12px;
  margin-top: 12px;
  /*设置单行 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fujian-name {
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
  margin-top: 4px;
}
.drawer-sucai {
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;

}
.fujian-pic {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
 

}
.fujian-txt {

  width: 40px;
  height: 40px;


}</style>