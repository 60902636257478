<!--  -->
<template>

  <div>
    <div class="addqiepian">
    <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose" top="60px">
      <div class="div-dialog">
        <div class="drawer-head">
          <p>导入切片</p>
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png" @click="closeDialog(1)">
        </div>
        <div class="div-prompt" v-loading="loading">

          <div class="div-upload">

            <el-upload action="" :show-file-list="false" :before-upload="beforeUpload" :http-request="httpRequest">
              <div class="p-upload" v-if="fileName.length == 0">上传文件 </div>

              <div class="div-reupload" v-else>

                <p class="p-reupload">{{ fileName }}</p>
                <p class="p-reupload1">重新上传</p>
              </div>
            </el-upload>


            <p class="p-uptip">*请按照模板格式填写，完成后导出csv格式上传，大小保持在 10M 以内</p>
            <p class="p-updown" @click="downloadCsv">点击下载模板</p>
          </div>




        </div>

        <div class="div-yulan">
          <p class="p-yulan">切片预览</p>

          <p class="p-yulant">共计{{ roleList.length }}条内容</p>
        </div>

        <div class="list-div">
          <div class="list-item" v-for="(item, index) in roleList" :key="item.id">

            <p class="p-ques">{{ item.question }}</p>

            <p class="p-ans">{{ item.answer }}</p>

            <div class="list-delete" @click="beginDelete(index)">

              <i class="el-icon-delete" style="color:#ffffff ;"></i>
              <p>删除</p>
            </div>

          </div>

        </div>

        <div class="bottom-div">
          <p class="btm-p">导入时全部启用</p>
          <el-switch v-model="value" :width="32">
          </el-switch>
          <div class="btm-btn">
            <button class="cancle-btn" @click="closeDialog(2)">
              取消导入
            </button>
            <el-button type="primary" :disabled="roleList == undefined || roleList == null || roleList.length == 0"
              :loading="btnloading" @click="confimisImport">确认导入</el-button>


          </div>
        </div>



      </div>

    </el-dialog>

  </div>
 


<div class="tipdiaolog">
  <el-dialog :visible.sync="dialogshow" :show-close="false">
      <div class="div-dialog-tip">
        <div class="dialog-head-tip">
          <p class="dialog-head-p-tip">操作提示</p>
          <img class="close_dialog-tip" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"
            @click="dialogshow = false">
        </div>

        <div class="dialog-content-tip">

          <p>{{ dialogcontent }}</p>

        </div>

        <div class="dialog-bottom-tip">

          <button class="dialog-negative" @click="dialogshow = false">取消</button>
          <button class="dialog-positive" @click="dialogConfim">确认</button>
        </div>
      </div>

    </el-dialog>
</div>
</div>

</template>

<script>
import { knowledgeFileCV, fileStatusV1, findQAListV2, isImport, deleteQA } from "api/aiworker/aiworker.js";
import common from "common/js/common";
export default {
  data () {
    return {
      show: false,
      loading: false,
      roleList: [],
      value: true,
      empId: null,
      fileName: "",
      fileId: null,
      btnloading: false,

      dialogshow: false,
      dialogType: 1,
      dialogcontent: "",
      roleIndex:-1
    }
  },
  methods: {

    handleClose (done) {

    },
    downloadCsv () { 

      const link = document.createElement('a');     
      link.download = '切片模板.csv' // 下载文件的名称
      link.style.display = 'none'
      link.href = 'http://cdn-upload.shhd.info/knowlBaseFile/切片模板.csv';
      link.click()
      link.remove()
    },
    showPopup1 (id1) {
      this.empId = id1;
      this.show = true;

    },
    closeDialog (i) {
      if (i == 1) {
        this.show = false;
        this.fileName = "";
        this.fileId = "";
        this.roleList = [];
      } else { 
        this.dialogshow = true;
        this.dialogType = 1;
        this.dialogcontent = "是否确认取消导入切片？";
      }
    
    },



    beginDelete (i) {

      this.dialogshow = true;
      this.dialogType = 2;
      this.roleIndex = i;
      this.dialogcontent = "删除后不可恢复，是否确认删除？";
    },
    deleteQaAll (i) {
      let arr = new Array();
      arr.push(this.roleList[i].id)
      let obj = {
        "ids": arr,
        "empId": this.empId
      }
      deleteQA(obj).then((res) => {
        console.log(res);

        if (res.code == 200) {

          this.$message({
            message: '删除成功',
            type: 'success'
          });

          this.roleList.splice(i,1);
        } else {
          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }

        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }

      });
    },



    dialogConfim () {
      this.dialogshow = false;
      if (this.dialogType == 1) {
        this.show = false;
        this.fileName = "";
        this.fileId = "";
        this.roleList = [];
      } else if (this.dialogType == 2) {

        this.deleteQaAll(this.roleIndex);
        
      }
    },


    beforeUpload (file) {
      //   const isJPG = file.type === 'image/jpeg';

      let fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);

      if (fileExtension === 'csv') {
        // 处理符合要求的文件
        if (file.size / 1024 / 1024 < 10) {

          return true;
        } else {
          this.$message.error('上传文档大小不能超过10M！');
          return false;
        }

      } else {
        this.$message.error('请上传正确的文档格式');
        return false;
      }
    },

    httpRequest (file2) {

      this.fileName = file2.file.name;
      this.loading = true;
      let fd = new FormData();
      fd.append('empId', this.empId);
      fd.append('userId', this.$localstore.get("hdUserId"));
      fd.append('access_token', this.$localstore.get("access_token"));
      fd.append('files', file2.file)

      knowledgeFileCV(fd).then((res) => {
        this.loading = false;
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '上传成功',
            type: 'success'
          });
          this.fileId = res.fileId;
          this.refreshQAList(res.fileId);
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          } else {
            this.$message.error('上传失败，请重试！');
          }

        }
      }).catch((err) => {
        this.loading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message.error(JSON.stringify(err.message));
        }

      });
    },
    refreshFileStatusV1 (id1) {
      setTimeout(() => {
        fileStatusV1(id1).then((res) => {
          console.log(res);

          if (res.code == 200) {

            if (res.data == 0) {
              this.loading = false;
              this.$message({
                message: '文件解析成功',
                type: 'success',
                duration:5000
              });

              this.refreshQAList(id1);


            } else if (res.data == 1) {
              this.loading = false;
              this.$message({
                message: '文件解析失败,请重新上传重试',
                type: 'error',
                duration:5000
              });


            } else {
              this.refreshFileStatusV1(id1);
            }
          }


        }).catch((err) => {
          this.loading = false;
        });

      }, 3000);
    },


    refreshQAList (fileid) {
      findQAListV2(this.empId, fileid).then((res) => {

        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.roleList = res.data;
        } else if (res.code == 400) {
          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          }
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {

        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });

    },
    confimisImport () {
      this.btnloading = true;

      let status = 1;
      if (this.value) {
        status = 1
      } else { 
        status = 0
      }
      isImport(this.fileId,status,this.empId).then((res) => {
        this.btnloading = false;

        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: '导入成功',
            type: 'success'
          });
      this.show = false;
      this.fileName = "";
          this.fileId = "";
          this.roleList = [];
          this.addconfimAction();
        } else if (res.code == 400) {
          if (!common.isStringEmpty(res.message)) {
            this.$message.error(res.message);
          }
        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {
        this.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });

    },

    addconfimAction () { 
      

      this.$emit('addconfimAction')
    
    },

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {

  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-dialog {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.drawer-head {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.drawer-head p {
  flex: 1;
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 20px;
  margin-left: 30px;
}

.drawer-head img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}


.div-prompt {
  background: #F9FAFB;

  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px dashed #D7E4F3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
}

.div-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.p-upload {
  width: 104px;
  height: 38px;
  background: #226CFF;
  border-radius: 22px 22px 22px 22px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 38px;
  margin-top: 30px;
  text-align: center;
}

.div-reupload {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.p-reupload {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 16px;
}

.p-reupload1 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #226CFF;
  line-height: 14px;
  margin-left: 10px;
}


.p-uptip {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 18px;
  margin-top: 8px;
}

.p-updown {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  line-height: 14px;
  border-bottom: 1px solid #226CFF;
  margin-top: 14px;
  margin-bottom: 28px;
  cursor: pointer;
}

.div-yulan {
  display: flex;
  align-items: center;
  margin-top: 20px;

}

.p-yulan {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  margin-left: 30px;

}

.p-yulant {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
  margin-left: 6px;
}

.list-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 15px;
  overflow: auto;
}

.list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 49%;
  height: 138px;
  background: #F4F7F9;
  box-shadow: 0px 2px 3px 0px rgba(9, 56, 128, 0.05);
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
  border: 1px solid #EAF1F5;
}

.list-item:hover {
  border: 1px solid #65AAFB;
}

.list-item:hover .list-delete {
  display: flex;
}

.p-ques {
  margin-top: 14px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;

  line-height: 18px;
  /* 设置多行*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* 设置省略字母数字 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.p-ans {
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 18px;
  /* 设置多行*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* 设置省略字母数字 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.list-delete {

  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  width: 70px;
  height: 70px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 35px;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.list-delete p {

  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 12px;
  margin-top: 2px;
}

.bottom-div {
  display: flex;
  height: 70px;
  align-items: center;
}

.btm-p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
  margin-left: 30px;
  margin-right: 8px;
}

.btm-btn {
  flex: 1;
  display: flex;
  justify-content: right;
}

.cancle-btn {
  width: 160px;
  height: 52px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #D7E4F3;
  font-size: 16px;
  background-color: #ffffff;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-right: 15px;
}

.btm-btn /deep/ .el-button {
  width: 160px;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  margin-right: 30px;
}

.addqiepian /deep/.el-dialog {

  width: 900px;
  height: 800px;
  background: #FFFFFF;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  margin-top: 30px;
}

.tipdiaolog /deep/.el-dialog {

width: 400px;
height: 260px;
background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
border-radius: 14px 14px 14px 14px;
opacity: 1;
border: 1px solid #FFFFFF;
}

 /deep/.el-dialog__header {

  display: none;

}

 /deep/.el-dialog__body {

  padding: 0;
}






.div-dialog-tip {
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: column;
}

.close_dialog-tip {
  width: 36px;
  height: 36px;

  align-self: flex-end;
  position: absolute;
  right: 12px;

}

.dialog-head-tip {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
}

.dialog-head-p-tip {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);

}

.dialog-content-tip {
  display: flex;
  width: 100%;
  flex: auto;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  color: #000000;
  padding-left: 10px;
  padding-right: 10px;


}

.dialog-bottom-tip {
  display: flex;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-left: 19px;
  margin-right: 19px;
}

.dialog-negative {
  flex: auto;
  height: 52px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 1px solid #D7E4F3;
  font-size: 14px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
  line-height: 52px;
  text-align: center;
}



.dialog-positive {
  flex: auto;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  border: none;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 52px;
  text-align: center;
  margin-left: 15px;
}











.pop-content {
  display: flex;
}</style>